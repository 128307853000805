import React from 'react'
import { useContext } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AppContext } from './AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Contacts.js.scss';
import FlagIco from './FlagIco';


export const Contacts = ({ onClose }) => {

    const { t, pathConfig } = useContext(AppContext);

    const showMk = ['malekarpaty', 'mkparking'].includes(_.get(pathConfig, 'key'));

    return (
        <Modal
            className={'contacts-modal'}
            isOpen={true}
            autoFocus={false}
            centered
            toggle={onClose}
            backdrop='static'
        >
            <ModalHeader>
                <span>{t('Contacts.Title')}</span>
            </ModalHeader>
            <ModalBody>
                <div className="label">{t('Contacts.Manual')}</div>
                <div className="file">
                    <a
                        className="file-link"
                        href={encodeURI(`/doc/${t('Contacts.Manuals.MainFile')}`)}
                        //download={t('Contacts.Manuals.MainName')}
                        target={t('Contacts.Manuals.MainFile')}
                    >
                        <span className='prefix-ico'><FontAwesomeIcon icon="fa-solid fa-file-alt" /></span>
                        {t('Contacts.Manuals.Main')}
                    </a>
                </div>
                {!showMk &&
                    <>
                        <div className="label break">{t('Contacts.Support')}</div>
                        <div className="tel">
                            <a href="tel:+420277001205" className="link">
                                <span className='prefix-ico'><FlagIco country='cs' /></span>
                                <span>+420 277 001 205</span>
                            </a>
                        </div>
                        <div className="tel">
                            <a href="tel:+421940504433" className="link">
                                <span className='prefix-ico'><FlagIco country='sk' /></span>
                                <span>+421 940 504 433</span>
                            </a>
                        </div>
                        <div className="mail">
                            <a href="mailto:support@xmarton.com" className="link">
                                <span className='prefix-ico'><FontAwesomeIcon icon="fa-solid fa-envelope" /></span>
                                <span>support@xmarton.com</span>
                            </a>
                        </div>
                    </>
                }
                {showMk &&
                    <>
                        <div className="label break">{t('Contacts.Support')}</div>
                        <div className="telmail">
                            <a href="tel:+421940504433" className="link">
                                <span className='prefix-ico'><FlagIco country='sk' /></span>
                                <span>+421 940 504 433</span>
                            </a>
                            <a href="mailto:podpora@xmarton.com" className="link">
                                <span className='prefix-ico'><FontAwesomeIcon icon="fa-solid fa-envelope" /></span>
                                <span>podpora@xmarton.com</span>
                            </a>
                        </div>
                        <div className="label break">{t('Contacts.InfoSupport')}</div>
                        <div className="telmail">
                            <span>Pezinok</span>
                            <a href="tel:+421336901820" className="link">
                                <span className='prefix-ico'><FlagIco country='sk' /></span>
                                <span>+421 33 6901 820</span>
                            </a>
                            <a href="mailto:infocentrum@pezinok.sk" className="link">
                                <span className='prefix-ico'><FontAwesomeIcon icon="fa-solid fa-envelope" /></span>
                                <span>infocentrum@pezinok.sk</span>
                            </a>
                        </div>
                        <div className="label break">{t('Contacts.ServisSupport')}</div>
                        <div className="telmail">
                            <span>Pezinok</span>
                            <a href="tel:+421910872926" className="link">
                                <span className='prefix-ico'><FlagIco country='sk' /></span>
                                <span>+421 910 872 926</span>
                            </a>
                        </div>
                    </>
                }
            </ModalBody>
            <ModalFooter className='right-align'>
                <Button
                    color="carshare"
                    onClick={onClose}
                >{t('Btn.Close')}</Button>
            </ModalFooter>
        </Modal>
    );
}