import './FlagIco.jsx.scss';


function FlagIco({ country }) {

    return (
        <img
            className='flag-ico'
            alt={country}
            src={`/flags/${country}.png`}
        />
    );
}

export default FlagIco;